<template>
  <div class="contact-page-container">
    <div class="contact-content">
      <Rotation :rotate="path" />
      <div class="contact-map">
        <div class="contact-text">
          <h4>İrtibat</h4>
          <hr class="mini-hr" />
          <br />
          <i class="fas fa-map-marker-alt" style="color: orangered"></i>
          <p>
            Has Emek Sanayi Sitesi,<br />
            1464. Sokak, No:23,<br />
            Ostim Sanayi, ANKARA
          </p>
          <br />

          <i class="fas fa-phone-alt" style="color: orangered"></i>
          <p>+ 90 538 766 98 52</p>

          <i class="fas fa-envelope" style="color: orangered"></i>
          <p>cozumpaslanmaz@gmail.com</p>
        </div>

        <div class="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.7253220370126!2d32.73040321563954!3d39.99224328926946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349f12c050b37%3A0x64c8ec8afaae943!2sSerhat%2C%201464.%20Sk.%20No%3A23%2C%2006378%20%C4%B0vedik%20Osb%2FYenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1621308744662!5m2!1str!2str"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <form @submit.prevent="onSubmit()" class="contact-form-container">
        <div class="form-side">
          <div class="form-group">
            <input
              v-model="message.name"
              type="text"
              required
              placeholder="Ad Soyad*"
            />
          </div>

          <div class="form-group">
            <input
              v-model="message.phone"
              type="tel"
              maxlength="11"
              placeholder="Telefon"
            />
          </div>

          <div class="form-group">
            <input
              v-model="message.email"
              type="email"
              required
              placeholder="Email*"
            />
          </div>
        </div>
        <div class="form-side">
          <div class="form-group">
            <textarea
              v-model="message.message"
              cols="30"
              rows="10"
              placeholder="Mesajınız...*"
              required
            ></textarea>
            <h6 class="required">* Yıldızlı alanlar gereklidir.</h6>
            <div id="status" v-if="messageStatus == true">Mesaj Gönderildi</div>

            <button type="submit">Gönder</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Rotation from "../components/global/Rotation.vue";

export default {
  name: "Contact",
  data() {
    return {
      path: {
        name: "İletişim",
      },
      message: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      messageStatus: false,
    };
  },
  components: { Rotation },
  methods: {
    onSubmit() {
      this.$store.dispatch("sendMessage", this.message).then(() => {
        this.messageStatus = this.$store.state.message.messageStatus;

        setTimeout(() => {
          this.messageStatus = false;
          this.message = "";
        }, 3000);
      });
    },
  },
};
</script>
<style lang="scss">
@import "../scss/responsive.scss";

.contact-page-container {
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  .contact-content {
    width: 1400px;
    display: flex;
    flex-direction: column;
    .contact-map {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 1rem;

      .contact-text {
        width: 25%;
        color: #777777;
        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }
        @include md {
          width: 100%;
        }
      }
      .map-container {
        overflow: hidden;
        width: 70%;
        position: relative;
        height: 500px;
        iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
        }
        @include xs {
          width: 100%;
          height: 200px;
        }
        @include sm {
          width: 100%;
          height: 300px;
        }
        @include md {
          width: 100%;
        }
      }
    }
  }

  .contact-form-container {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include xs {
      flex-direction: column;
      align-items: center;
    }
    @include sm {
      flex-direction: column;
      align-items: center;
    }
    .form-side {
      width: 30%;
      display: flex;
      flex-direction: column;
      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }
      &:nth-child(1) {
        margin-right: 1rem;
        @include xs {
          margin-right: 0;
        }
        @include sm {
          margin-right: 0;
        }
      }
    }
  }
  .required {
    color: #777777;
    font-size: 14px;
  }

  .form-group input {
    height: 50px;
    outline: none;
    border-radius: 10px;
    margin-bottom: 16px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: white;
  }
  .form-group textarea {
    outline: none;
    border-radius: 10px;
    height: 250px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: white;
    resize: none;
  }

  button[type="submit"] {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    color: orangered;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    transition: 0.1s ease;
    background-color: white;
    border: 1px solid #ccc;
  }
  button[type="submit"]:hover {
    font-weight: 600;
  }
  #status {
    width: 100%;
    color: green;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
}
</style>
